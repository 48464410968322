<template>
  <div class="onjob">
    <el-card class="box-card condition-box">
      <el-row style="padding: 0 10px">
        <el-col :span="14">
          <div class="header-left">
            <div class="font14">offer时间查询：</div>
            <el-date-picker
              type="date"
              placeholder="起"
              v-model="pageInfo.startDate"
              style="width: 182px"
            ></el-date-picker>
            <span style="margin: 0 15px"></span>
            <el-date-picker
              type="date"
              placeholder="止"
              v-model="pageInfo.endDate"
              @change="selectStatus"
              style="width: 182px"
            ></el-date-picker>
          </div>
        </el-col>
        <el-col :span="10">
          <div class="header-right" style="text-align: right">
            <el-select
              v-model="pageInfo.companyJobId"
              placeholder="全部职位"
              @change="selectStatus"
              style="width: 130px; margin-right: 50px"
            >
              <el-option
                v-for="item2 in positionoptions"
                :key="item2.value"
                :label="item2.label"
                :value="item2.value"
              ></el-option>
            </el-select>
            <el-select
              v-model="pageInfo.status"
              placeholder="offer状态"
              @change="selectStatus"
              style="width: 130px"
            >
              <el-option
                v-for="item2 in offeroptions"
                :key="item2.value"
                :label="item2.label"
                :value="item2.value"
              ></el-option>
            </el-select>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <!-- <div class="handshake-tishi">您有5条待面试 ，3条面试已拒绝</div> -->
    <!-- 无数据 -->
    <div class="nodata" v-if="userList != null && userList.length == 0">
      <img src="../../assets/images/person/p-nodata.png" />
      <div class="font16">当前无数据</div>
    </div>
    <el-card class="p-list" v-for="(itemes, index) in userList" :key="index">
      <div class="process" @click="todetail(itemes)">
        <div class="p-left">
          <div class="pl-one">
            <div class="p-picurl">
              <img
                :src="'https://freemen.work/user/file/view/' + itemes.avatar"
              />
              <span
                class="bofang font20"
                v-if="itemes.resUrl"
                @click.stop="videoshow(itemes)"
              >
                <!-- <i class="el-icon-video-play"></i> -->
                <img src="../../assets/images/common/bofangicon.png" />
              </span>
            </div>
            <div class="p-info">
              <div class="pn-name">
                <div class="namemain">
                  <span class="font22">{{ itemes.displayName }}</span>
                  <img
                    src="../../assets/images/person/boy.png"
                    v-if="itemes.sex == 1"
                  />
                  <img src="../../assets/images/person/girl.png" v-else />
                  <span class="ty font14" v-if="itemes.status == '0'"
                    >待回复</span
                  >
                  <span class="ty font14" v-if="itemes.status == '1'"
                    >已同意</span
                  >
                  <span class="dai font14" v-if="itemes.status == '2'"
                    >已拒绝</span
                  >
                  <span class="jujue font14" v-if="itemes.status == '3'"
                    >待接受</span
                  >
                  <span class="jujue font14" v-if="itemes.status == '4'"
                    >已过期</span
                  >
                  <span class="jujue font14" v-if="itemes.status == '5'"
                    >公司已拒绝</span
                  >
                  <span class="jujue font14" v-if="itemes.status == '6'"
                    >后台已发送简历</span
                  >
                </div>
                <div class="score font14">
                  <span>{{ itemes.score }}</span>
                </div>
              </div>
              <div class="beizhu font14">
                <!-- {{itemes.expYear}}年 | {{itemes.educationType ? itemes.educationType : '不限'}} | {{new Date().getYear() - new Date(itemes.birthday).getYear()}}岁 | {{itemes.jobLevelNames}} -->
                {{ itemes.expYear }}年 |
                {{
                  new Date().getYear() -
                  new Date(itemes.birthday).getYear() -
                  (new Date().getMonth() > new Date(itemes.birthday).getMonth()
                    ? 0
                    : 1)
                }}岁 | {{ itemes.jobLevelNames }}
              </div>
            </div>
          </div>
          <div class="pl-two font16">应聘岗位：{{ itemes.companyJobName }}</div>
          <div
            class="pl-two font16"
            v-if="itemes.status == 2 || itemes.status == 5"
          >
            拒绝理由：
            <template v-for="(item, ii) in itemes.processList">
              <template v-if="item.reasons">
                <span v-for="(i, index) in item.reasons" :key="index">
                  {{ i.itemText }},
                </span>
                <span :key="ii"> {{ item.reasonDescription }} </span>
              </template>
            </template>
          </div>
        </div>
        <el-divider direction="vertical"></el-divider>
        <div class="p-right font14">
          <div class="" @click.stop="todetail(itemes)">
            评定职级：{{ itemes.jobLevelName }}
          </div>
          <!-- <div class="" @click="todetail(itemes)">联系电话：{{}}</div> -->
          <div class="" @click.stop="todetail(itemes)">
            到岗时间：{{ itemes.joinDate }}
          </div>
          <div class="" @click.stop="todetail(itemes)">
            公司地址：{{ itemes.companyBuild }}
          </div>
          <div class="" @click.stop="todetail(itemes)">
            Offer发送时间：{{ itemes.createDate }}
          </div>
          <el-button
            class="again"
            type="primary"
            @click.stop="updateOffer(itemes)"
            v-if="
              itemes.status == '0' ||
              itemes.status == '1' ||
              itemes.status == '4' ||
              itemes.status == '5'
            "
            >重新编辑offer</el-button
          >
          <el-button
            class="again"
            @click.stop="reSend(itemes)"
            type="primary"
            v-if="itemes.status == '2' || itemes.status == '4'"
            >重新发送offer</el-button
          >
          <!-- <el-button class="again2" type="primary" @click="agree(itemes,1)" v-if="itemes.status=='1' || itemes.status=='3'">同意</el-button>
					<el-button class="again" type="primary" @click="agree(itemes,5)" v-if="itemes.status=='1' || itemes.status=='3'">拒绝</el-button> -->
        </div>
      </div>
    </el-card>
    <div class="nextpage" @click="getMoreList" v-if="moreBtn">点击加载更多</div>
    <!-- 提交成功 -->
    <el-dialog
      :visible.sync="upDialogVisible"
      width="600px"
      center
      :show-close="false"
      :modal-append-to-body="false"
    >
      <div class="updown_box">
        <div class="ub-img">
          <img src="../../assets/images/common/gou-chenggong.png" />
        </div>
        <div class="ub-text font22">上架成功</div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="upDateOfferShow"
      width="700px"
      :show-close="false"
      :modal-append-to-body="false"
      :lock-scroll="false"
    >
      <div class="details">
        <span class="closeicon" @click="upDateOfferShow = false"
          ><i class="el-icon-error"></i
        ></span>
        <div class="wb-tit font18">发送Offer</div>
        <div class="updatabox">
          <el-form
            :model="infoValidateForm"
            ref="infoValidateForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item
              label="职级名称："
              prop="jobLevelId"
              :rules="[
                { required: true, message: '请选择职级名称', trigger: 'blur' },
              ]"
            >
              <el-select
                v-model="infoValidateForm.jobLevelId"
                placeholder="请选择"
                style="width: 450px"
              >
                <el-option
                  v-for="item in jobLevelOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="到岗时间："
              prop="joinDate"
              :rules="[
                { required: true, message: '请选择到岗时间', trigger: 'blur' },
              ]"
            >
              <el-date-picker
                v-model="infoValidateForm.joinDate"
                type="datetime"
                placeholder="请选择到岗日期"
                style="width: 450px"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              label="公司地址："
              prop="companyBuild"
              :rules="[
                { required: true, message: '请选择公司地点', trigger: 'blur' },
              ]"
            >
              <el-input
                v-model="companyAdress"
                placeholder="请选择公司地点"
                style="width: 450px"
                @click.native="(upDateOfferShow = false), (adressShow = true)"
              >
              </el-input>
            </el-form-item>
            <!-- submitForm('infoValidateForm') -->
            <el-form-item>
              <!-- <el-button class="qux" @click="upDateOfferShow = false">取消</el-button> -->
              <el-button
                type="primary"
                class="btn"
                @click="submitForm('infoValidateForm')"
              >
                确认发送
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="adressShow"
      width="700px"
      :show-close="false"
      :modal-append-to-body="false"
      :lock-scroll="false"
    >
      <div class="details">
        <span class="closeicon" @click="adressShow = false">
          <i class="el-icon-error"></i>
        </span>
        <div class="wb-tit font18">工作地点</div>
        <div class="frombox">
          <el-form
            :model="infoValidateForm"
            ref="updataForm"
            size="small"
            label-width="130px"
            class="demo-ruleForm"
          >
            <!-- <el-form-item
              label="街道/写字楼："
              prop="address"
              :rules="[
                { required: true, message: '请输入行政区、街道、写字楼' },
              ]"
            >
              <el-input
                v-model="infoValidateForm.address"
                placeholder="请输入行政区、街道、写字楼"
                style="width: 430px"
              ></el-input>
            </el-form-item> -->
            <el-form-item
              label="街道/写字楼："
              prop="address"
              :rules="[
                { required: true, message: '请输入行政区、街道、写字楼' },
              ]"
            >
              <el-select
                v-model="tipInput"
                class="tipInput"
                filterable
                remote
                reserve-keyword
                clearable
                placeholder="请输入行政区、街道、写字楼"
                :remote-method="remoteMethod"
                :loading="loading"
                @change="tipChange"
                style="width: 100%"
              >
                <el-option
                  v-for="item in tips"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.name }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.district
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="门牌号："
              prop="companyBuild"
              :rules="[{ required: true, message: '请填写真实地址' }]"
            >
              <el-input
                v-model="infoValidateForm.companyBuild"
                placeholder="请填写真实地址"
                style="width: 100%"
              ></el-input>
            </el-form-item>
            <img
              v-if="!markers.length"
              class="amap-wrapper"
              style="width: 100%"
              src="../../assets/images/common/map.png"
            />
            <div class="amap-wrapper" v-else style="width: 100%">
              <el-amap
                class="amap-box"
                :zoom="zoom"
                :center="center"
                :mapStyle="mapStyle"
              >
                <el-amap-marker
                  v-for="(marker, index) in markers"
                  :position="marker.position"
                  :events="marker.events"
                  :vid="index"
                  :icon="marker.icon"
                  v-bind:key="index"
                >
                </el-amap-marker>
                <el-amap-info-window
                  v-if="window"
                  :position="window.position"
                  :visible="window.visible"
                  :content="window.content"
                  :offset="window.offset"
                  :is-custom="true"
                >
                  <div id="info-window">
                    <p>{{ window.address }}</p>
                  </div>
                </el-amap-info-window>
              </el-amap>
            </div>
            <el-form-item>
              <el-button class="qux" @click="adressShow = false"
                >取消</el-button
              >
              <el-button
                type="primary"
                class="btn-next"
                @click="saveAdress('updataForm')"
                >保存地址</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import location from "../../assets/images/size.png";

export default {
  data() {
    return {
      zoom: 14,
      center: [],
      mapStyle: "amap://styles/8b6be8ec497009e17a708205348b899a", //设置地图样式
      markers: [],
      windows: [],
      tipInput: "",
      loading: false,
      map: undefined,
      tips: [],
      window: "",
      formInline: {
        offerstuts: "全部",
        startTime: "",
        endTime: "",
        type: "",
        stuts: "",
        pageNum: 1,
        pageSize: 10,
      },
      pageInfo: {
        pageNo: 1,
        pageSize: 10,
        companyJobId: "",
        status: "",
        startDate: "",
        endDate: "",
      },
      moreBtn: false,
      // 全部状态
      positionoptions: [{ value: "", label: "全部职位" }],
      offeroptions: [
        { value: "", label: "全部offer" },
        { value: 0, label: "待回复" },
        { value: 1, label: "已同意" },
        { value: 2, label: "已拒绝" },
        { value: 3, label: "待同意" },
      ],
      userList: null,
      upDialogVisible: false, //提交成功弹窗
      upDateOfferShow: false,
      infoValidateForm: {
        jobLevelId: "",
        address: "",
        companyBuild: "",
        joinDate: "",
        companyJobId: "",
        offerSalary: "",
        userOfferId: "",
        userJobId: "",
      },
      jobLevelOptions: [],
      companyAdress: "",
      adressShow: false,
    };
  },
  watch: {
    adressShow(value) {
      if (!value) {
        this.adressShow = false;
        this.upDateOfferShow = true;
      }
    },
  },
  activated() {
    this.getJobList();
    this.getList();
    this.getJobLevel();
  },
  methods: {
    tipChange(value) {
      for (const tip of this.tips) {
        if (value === tip.id) {
          this.infoValidateForm.address = tip.name;
          let markers = [];
          let windows = [];
          let basePosition = [tip.location.lng, tip.location.lat];
          // let icon = new AMap.Icon({
          // 	image: location,
          // 	size: new AMap.Size(2, 2),
          // 	imageSize: new AMap.Size(2, 2)
          // });
          // content添加相应的内容**********
          markers.push({
            position: basePosition,
            icon: new AMap.Icon({
              image: location,
              size: new AMap.Size(40, 40),
              imageSize: new AMap.Size(40, 40),
            }),
            text: "content1111",
            offset: [0, -50],
          });
          windows.push({
            position: basePosition,
            isCustom: true,
            offset: [10, -40], // 窗体偏移
            visible: true, // 初始是否显示
            address: tip.name,
          });
          this.zoom = 15;
          this.markers = markers;
          this.windows = windows;
          this.center = [tip.location.lng, tip.location.lat];
          this.window = windows[0];
          break;
        }
      }
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        const that = this;
        AMap.plugin("AMap.Autocomplete", function () {
          // 实例化Autocomplete
          const autoOptions = {
            city: "全国",
          };
          const autoComplete = new AMap.Autocomplete(autoOptions);
          autoComplete.search(query, function (status, result) {
            console.log(">>>>>>", result);
            // 搜索成功时，result即是对应的匹配数据
            that.tips = result.tips || [];
            that.loading = false;
          });
        });
      } else {
        this.tips = [];
      }
    },
    getJobList() {
      //获取职位列表
      this.$api.joblist("get").then((res) => {
        this.positionoptions = [];
        res.data.map((item) => {
          if (item.status == 1) {
            let obj = {
              label: item.jobName,
              value: item.id,
            };
            this.positionoptions.push(obj);
          }
        });
      });
    },
    getJobLevel() {
      this.$api.getJoblevel("get").then((res) => {
        let arr = [];
        res.data.map((item) => {
          let obj = {
            value: item.id,
            label: item.rankName,
          };
          arr.push(obj);
        });
        this.jobLevelOptions = arr;
      });
    },
    getList() {
      //获取数据列表
      let config = JSON.parse(JSON.stringify(this.pageInfo));
      if (this.pageInfo.startDate) {
        let startTime = new Date(config.startDate);
        let y = startTime.getFullYear();
        let m = startTime.getMonth() + 1;
        let d = startTime.getDate();
        config.startDate = y + "-" + m + "-" + d;
      }
      if (this.pageInfo.endDate) {
        let endTime = new Date(config.endDate);
        let y = endTime.getFullYear();
        let m = endTime.getMonth() + 1;
        let d = endTime.getDate();
        config.endDate = y + "-" + m + "-" + d;
      }
      this.$api.getOfferList("get", config).then((res) => {
        // this.userList = this.userList.concat(res.data);
        if (config.pageNo == 1) {
          this.userList = res.data;
        } else {
          this.userList = this.userList.concat(res.data);
        }
        if (res.data.length < 10) {
          this.moreBtn = false;
        } else {
          this.moreBtn = true;
        }
        this.userList.map((item) => {
          item.moreShow = false;
        });
      });
    },
    getMoreList() {
      //下一页
      this.pageInfo.pageNo++;
      this.getList();
    },
    selectStatus() {
      //选择职位
      this.pageInfo.pageNo = 1;
      this.userList = [];
      this.getList();
    },
    handleCurrentChange(val) {
      this.fetchData(val);
    },
    todetail(data) {
      if (data.isCompanyRead != 1) {
        this.$api.readOffer("post", { id: data.id }).then(() => {});
      }
      this.$resumeDetail.show({
        show: true,
        type: "zaizhi",
        companyJobId: data.companyJobId,
        userJobId: data.userJobId,
        positionoptions: this.positionoptions,
        callback: () => {
          let tempQuery = {
            active: 1,
            companyJobId: data.companyJobId,
            companyJobName: data.companyJobName,
            userJobId: data.userJobId,
            userId: data.userId,
          }
          this.$router.push({ path: "chatmain", query: tempQuery});
        },
      });
    },
    videoshow(data) {
      let config = {
        show: true,
        data: data,
      };
      this.$videoDetail.show(config);
    },
    reSend(data) {
      this.$api
        .reSendOffer("post", { upPercent: 100, userOfferId: data.id })
        .then(() => {
          this.pageInfo.pageNo = 1;
          this.userList = [];
          this.getList();
          this.$message({
            message: "发送成功",
            type: "success",
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
    },
    agree(data, type) {
      this.$api
        .offerAccept("post", { status: type, userOfferId: data.id })
        .then(() => {
          this.pageInfo.pageNo = 1;
          this.userList = [];
          this.getList();
          this.$message({
            message: "操作成功",
            type: "success",
          });
        });
    },
    updateOffer(data) {
      this.infoValidateForm.companyJobId = data.companyJobId;
      this.infoValidateForm.userJobId = data.userJobId;
      this.infoValidateForm.jobLevelId = data.jobLevelId;
      this.infoValidateForm.address = data.address;
      this.infoValidateForm.companyBuild = data.companyBuild;
      this.infoValidateForm.joinDate = new Date(data.joinDate);
      this.infoValidateForm.userOfferId = data.id;
      this.companyAdress =
        this.infoValidateForm.address + this.infoValidateForm.companyBuild;
      this.upDateOfferShow = true;
    },
    saveAdress(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.adressShow = false;
          this.companyAdress =
            this.infoValidateForm.address + this.infoValidateForm.companyBuild;
        } else {
          return false;
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let config = JSON.parse(JSON.stringify(this.infoValidateForm));
          let startTime = new Date(config.joinDate);
          let y = startTime.getFullYear();
          let m = startTime.getMonth() + 1;
          let d = startTime.getDate();
          let h = startTime.getHours();
          let f = startTime.getMinutes();
          let s = startTime.getSeconds();
          config.joinDate = y + "-" + m + "-" + d + " " + h + ":" + f + ":" + s;
          if (this.center.length > 0) {
            config["lng"] = this.center[0];
            config["lat"] = this.center[1];
          }
          this.$api.upDateOffer("post", config).then(() => {
            this.upDateOfferShow = false;
            this.pageInfo.pageNo = 1;
            this.userList = [];
            this.getList();
            this.$message({
              message: "修改成功",
              type: "success",
            });
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.closeicon {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 26px;
  color: #f3f3f3;
}
.details {
  .wb-tit {
    padding: 17px 0;
    text-align: center;
    background-color: #f3f3f3;
    color: #000000;
    font-weight: 600;
  }
  .frombox {
    padding: 50px 50px 30px 50px;
    /deep/ .el-input__inner,
    /deep/ .el-textarea__inner {
      border-radius: 0px;
    }
    /deep/ .el-button {
      border-radius: 0px;
    }

    .demo-ruleForm {
      button {
        width: 200px;
        height: 40px;
      }
      .qux {
        margin-top: 50px;
        background-color: #fff;
        border: 1px solid #00bcff;
        color: #00bcff;
        margin-right: 20px;
      }
      .btn-next {
        background-color: #00bcff;
        border: none;
      }
    }
  }
}
.closeicon {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 26px;
  color: #f3f3f3;
}
.details {
  padding-bottom: 20px;
  .wb-tit {
    padding: 17px 0;
    text-align: center;
    background-color: #f3f3f3;
    color: #000000;
    font-weight: 600;
  }
}

.updatabox {
  .demo-ruleForm {
    padding: 50px 80px 0px 50px;
    /deep/ .el-input__inner,
    /deep/ .el-textarea__inner {
      border-radius: 0px;
      // width: 450px;
    }
    /deep/ .el-button {
      border-radius: 0px;
      width: 450px;
      margin-top: 50px;
      background: #00bcff;
      border: none;
    }
    .apply_tishi {
      display: flex;
      align-items: flex-start;
      width: 450px;
      .p-img {
        padding: 2px 8px 0 8px;
        color: #ff4444;
        display: flex;
        align-items: flex-start;
      }
      .p-cont {
        display: flex;
        flex: 1;
        color: #666666;
        line-height: 1.5;
        text-align: left;
      }
    }
  }
}
.nodata {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #111111;
  padding: 37px 0 20px 0;
  img {
    width: 256px;
    height: 215px;
    margin-bottom: 20px;
  }
}
.nextpage {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999999;
  cursor: pointer;
  margin-top: 15px;
}
.condition-box {
  // background-color: #ffffff;
  // padding: 22px 0;
  // box-shadow: 0px 1px 29px 17px rgb(0 0 0 / 3%);
  /deep/ .el-input__inner {
    border-radius: 0px;
    // border: none;
    // background-color: #f3f3f3;
  }
  .header-left {
    display: flex;
    align-items: center;
    color: #111111;
  }
  .header-right {
    /deep/ .el-input__inner {
      border-radius: 0px;
      // background-color: #00bcff;
      // border: none;
      // color: #FFFFFF;
    }
  }
}
.handshake-tishi {
  margin-top: 20px;
  background-color: #feecde;
  text-align: center;
  color: #ff5400;
  padding: 14px 0;
  box-shadow: 0px 1px 29px 17px rgb(0 0 0 / 3%);
}
.p-list:hover {
  transform: scale(1.02);
  background-color: rgba(0, 0, 0, 0.001);
}
.p-list {
  margin-top: 20px;
  position: relative;
  .zhezhao {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    .zzbg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgb(255 255 255 / 50%);
    }
    .caouo {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      position: relative;
      img {
        height: 100px;
        width: 100px;
        margin: 0 30px;
      }
    }
  }
  .process {
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 20px 30px;
    .p-left {
      padding-right: 30px;
      display: flex;
      flex-direction: column;
      flex: 1;
      .pl-one {
        display: flex;
        align-items: center;
        .p-picurl {
          margin-right: 15px;
          position: relative;
          img {
            width: 50px;
            height: 50px;
          }
          .bofang:hover {
            cursor: pointer;
          }
          .bofang {
            position: absolute;
            top: -3px;
            right: -6px;
            // border-radius: 50px;
            // color: #3F3F3F;
            img {
              width: 22px;
              height: 22px;
            }
          }
        }
        .p-info {
          display: flex;
          // align-items: center;
          justify-content: flex-start;
          flex-direction: column;
          flex: 1;
          .pn-name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            line-height: 1.5;
            .namemain {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              img {
                width: 24px;
                height: 24px;
                margin: 0 18px 0 10px;
              }
              .ty {
                background-color: #e9f9ff;
                color: #00bcff;
                padding: 2px 10px;
              }
              .jujue {
                background-color: #f3f3f3;
                color: #999999;
                padding: 2px 10px;
              }
              .dai {
                color: #ff5400;
                background-color: #feecde;
                padding: 2px 10px;
              }
            }
            .score {
              background-color: #00bcff;
              border-radius: 8px 8px 8px 0;
              color: #ffffff;
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .beizhu {
            color: #666666;
          }
        }
      }
      .pl-two {
        color: #111111;
        margin-top: 20px;
      }
    }
    /deep/.el-divider--vertical {
      height: 4em;
      background-color: #f3f3f3;
    }
    .p-right {
      display: flex;
      // align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      color: #666666;
      flex: 1;
      padding-left: 30px;
      line-height: 1.8;
      position: relative;
      .again {
        position: absolute;
        right: 0px;
        top: -20%;
        border-radius: 0px;
        height: 40px;
        width: 120px;
        margin-top: 50px;
        background: #00bcff;
        border: none;
      }
      .again2 {
        position: absolute;
        right: 0px;
        top: 0;
        border-radius: 0px;
        height: 40px;
        width: 120px;
        margin-top: -10px;
        background: #e6a23c;
        border: none;
      }
    }
  }
}
.updown_box {
  line-height: 3;
  padding: 40px 0;
  .ub-img {
    text-align: center;
    img {
      width: 60px;
      height: 60px;
    }
  }
  .ub-text {
    text-align: center;
    color: #111111;
  }
}
</style>
<style type="text/css">
.el-dialog__header,
.el-dialog__body {
  padding: 0 !important;
}
.tipInput {
  width: 300px;
}
.amap-wrapper {
  height: 250px;
  margin-bottom: 20px;
}
</style>
